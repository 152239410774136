<template>
    <div class="page">
        <div class="other">
             <div style="font-family: Microsoft YaHei;font-weight: 400;font-size: 24px;color: #333333;margin-right: 50px;">可提现金额: 
                <span style="color: #296FE1;">￥{{totalMoney}}</span>
            </div>
            <Input v-model="money" placeholder="请输入提现金额" style="width: 10vw;" />
            <Button type="primary" style="background: #296FE1;margin-left: 30px;" @click="goWithdraw">提现</Button>
        </div>
        <div class="content">
            <div class="top">
                <DatePicker type="daterange" @on-change="changeDate" placement="bottom-start" placeholder=" 开始时间 至 结束时间 " style="width:15vw" separator=" 至 "></DatePicker>
                <Select v-model="storePrams.withdraw_type" style="width: 10vw;margin: 0 30px;" placeholder="请选择类型">
                    <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <Select v-model="storePrams.status" style="width: 10vw;" placeholder="请选择状态">
                    <Option v-for="item in stateList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <Button type="primary" style="background: #296FE1;margin-left: 30px;" @click="search">搜索</Button>
            </div>
            <div style="min-height: 50vh;">
                <Table :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="type">
                        <div v-if="row.type == 1">线上提现</div>
                        <div v-if="row.type == 2">线下提现</div>
                    </template>
                </Table>
            </div>
            <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
        </div>
        <!-- 提现 -->
        <Modal v-model="withdrawModal" title="提现">
            <div class="content-modal" style="min-height: 100px;">
                <RadioGroup v-model="withdraw_type">
                    <Radio v-for="item in orderList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                </RadioGroup>
            </div>
            <div slot="footer">
                <Button type="primary" style="background: #296FE1;" @click="saveDelModal">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { balance_withdraw_list, balanceWithdraw } from "@/api/index";
    export default {
        data() {
            return {
                withdraw_type: '1',
                orderList:[
                    {label:'线上提现',value:'1'},
                    {label:'线下提现',value:'2'}
                ],
                withdrawModal: false,
                money: '',
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    start_date: '',
                    end_date: '',
                    withdraw_type: '-1',
                    status: '-1'
                },
                columnsTable: [
                    {title: '#',slot: "id",align:'center',width:'130px'},
                    {title: '金额',key: 'money',align:'center'},
                    {title: '类型',slot: 'type',align:'center'},
                    {title: '状态',key: 'status_text',align:'center'},
                    {title: '备注',key: 'reason',align:'center'},
                    {title: '时间',key: 'created_at',align:'center'},
                ],
                dataTable: [],
                typeList: [
                    { value: '-1', label: '所有' },
                    { value: '1', label: '线上' },
                    { value: '2', label: '线下' }
                ],
                stateList: [
                    { value: '-1', label: '所有' },
                    { value: '0', label: '待审核' },
                    { value: '1', label: '同意' },
                    { value: '2', label: '拒绝' }
                ],
                totalMoney: ''
            }
        },
        created(){},
        mounted(){
            this.balance_withdraw_list()
        },
        methods: {
            goWithdraw(){
                this.withdrawModal = true
            },
            saveDelModal(){
                if(!this.money){
                    this.$Message.error('请输入提现金额');
                    return
                }
                let data = {
                    money: this.money,
                    withdraw_type: this.withdraw_type
                }
                balanceWithdraw(data).then(res => {
                    this.withdrawModal = false
                    this.money = ''
                    this.balance_withdraw_list()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            search(){
                this.balance_withdraw_list()
            },
            balance_withdraw_list(){
                balance_withdraw_list(this.storePrams).then(res => {
                    this.dataTable = res.data.list.data
                    this.total = res.data.list.total
                    this.totalMoney = res.data.money
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            changeDate(e){
                if(e[0]){
                    this.storePrams.start_date = e[0]
                    this.storePrams.end_date = e[1]
                }else {
                    this.storePrams.start_date = ''
                    this.storePrams.end_date = ''
                }
            },
            pageChange(index) {
                this.storePrams.page = index;
                // 调列表方法
                this.balance_withdraw_list()
            }
        }
    }
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.other{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.content{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    min-height: 75vh;
}
.top{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
}
.Page{
    margin-top: 20px;
    text-align: right;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
    background: #F5F9FA;
}
// table中所有的边框 
::v-deep .ivu-table td{
    border-left: none;
    border-right: none;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
    color: #666666 !important;
}
.content-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>